@import "shared";

.cube {
    position: relative;
    height: fit-content;
    --box-offset: #{px(0)};
    --box-angle: -45deg;

    &.inverted {
        --box-angle: 45deg;
    }
}

.cube__inner {
    position: relative;
}

.cube__front {
    transform: translate(calc(var(--box-offset) * -1), var(--box-offset));
    z-index: 5;
    position: relative;
    border: 1px solid $black;

    .inverted & {
        transform: translate(calc(var(--box-offset) * 1), var(--box-offset));
    }
}

.cube__frontText {
    padding: px(35) px(45);
    background-color: $off-white;

    @include bp(mobile) {
        padding: px(22);
    }
}

.cube__frontHeading {
    @include body;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: px(10);
}

.cube__frontDescription {
    @include body-large;
}

.cube__back {
    @include position-100(absolute);
    z-index: 1;
    border: 1px solid $black;
}

.cube__backImage {
    @include position-100(absolute);
    object-fit: cover;
    max-width: 100%;
    z-index: 2;
}

.cube__backColor {
    @include position-100(absolute);
    background-color: $yellow;
}

.cube__left,
.cube__right {
    position: absolute;
    z-index: 2;
    top: calc(var(--box-offset) * 0.5);
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    width: var(--box-offset);
    height: 100%;
    transform: skewY(var(--box-angle));

    @include bp(mobile) {
        border-width: 2px;
        transform: skewY(var(--box-angle));
    }
}

.cube__left {
    left: calc(var(--box-offset) * -1);

    .inverted & {
        left: 0;
    }
}

.cube__right {
    left: calc(100% - var(--box-offset));

    .inverted & {
        left: 100%;
    }
}
