@import "shared";

.container {
}

.inner {
    @include vh(100);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cube {
    width: px(540);

    @include bp(mobile) {
        width: px(280);
    }
}

.front {
    padding: px(12) px(60) px(60);
    background-color: $off-white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include bp(mobile) {
        padding: px(12) px(20) px(60);
    }
}

.backContent {
    @include position-100(absolute);
    background-color: $red;
}

.title {
    @include h1;
    text-align: center;

    @include bp(mobile) {
        @include h2;
    }
}

.text {
    margin-top: px(10);
    @include body;
    text-align: center;
    margin-bottom: px(40);
}

.button {
    padding: px(5) px(8);
    border: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: px(160);
}

.buttonText {
    font-size: px(20);
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    margin-right: px(5);
}

.buttonArrow {
    width: px(15);
    color: $black;
}
